// Welcome 15 Offer popup styles
@import '../../../../scss/_theme-bootstrap';

.welcome-15 {
  padding-bottom: 20px;
  color: $white;
  height: 302px;
  overflow: hidden;
  .site-email-signup {
    &__success {
      padding: 2em 0 0 2.5em;
      background-color: transparent;
      &-header {
        font-size: 36px;
        font-weight: bold;
        letter-spacing: -1px;
        text-align: $ldirection;
        text-transform: none;
      }
      &-offer {
        font-size: 22px;
        letter-spacing: -1px;
        line-height: 1.1em;
        padding-bottom: 18px;
        text-align: $ldirection;
      }
      &-terms {
        font-size: 13px;
        line-height: 0.6;
        margin-bottom: 0;
        margin-top: 1em;
        text-align: center;
        width: 236px;
        a.terms-conditions-link {
          color: $white;
          font-size: 11px;
          text-decoration: underline;
        }
      }
    }
    &__terms-details {
      p {
        margin: 0;
      }
    }
    &__privacy {
      padding-top: 10px;
      padding-bottom: 0;
      width: 80%;
    }
  }
  .site-email-signup__success--welcome-15 {
    form {
      text-align: $ldirection;
      .email_input {
        input[type='email'],
        input[type='text'] {
          color: $color-medium-gray;
          margin: 0;
          padding: 4px 0 0;
          text-align: center;
        }
        input[type='text'] {
          &.first-name {
            margin-top: 5px;
          }
        }
        input[type='text'],
        input[type='email'],
        input[type='url'],
        input[type='password'],
        input[type='tel'],
        input[type='number'],
        input[type='search'],
        input[type='date'],
        input[type='time'],
        textarea,
        input[type='submit'],
        input[type='button'],
        button {
          display: block;
          height: 33px;
          width: 236px;
        }
        input[type='submit'],
        input[type='button'],
        button {
          background: transparent none repeat scroll 0 0;
          border: 1px solid $white;
          margin-top: 15px;
        }
        input[type='submit']:hover,
        input[type='submit']:active,
        input[type='button']:hover,
        input[type='button']:active,
        button:hover,
        button:active {
          background: transparent none repeat scroll 0 0;
          border: 1px solid $white;
          color: $white;
          margin-top: 15px;
        }
      }
    }
  }
  &.error-msg {
    .site-email-signup__success-offer--line1 {
      padding-bottom: 2px;
    }
  }
  .popup-offer__sign-up-form-error-messages {
    color: red;
    font-size: 12px;
    max-width: 270px;
  }
}
/* Welcome15 success popup */

.welcome-15.success {
  padding: 3.2em 0 0 1.5em;
  .site-email-signup {
    &__success {
      padding: 3.5em 0 0 1.5em;
      &-header {
        font-size: 42px;
        font-weight: bold;
        letter-spacing: -1.5px;
        line-height: 40px;
        text-transform: none;
        padding-bottom: 20px;
      }
      &-offer {
        font-size: 26px;
        letter-spacing: -0.5px;
        line-height: 1.1em;
        padding-bottom: 0;
        &--line1 {
        }
        &--line2 {
        }
        &__code {
          font-weight: bold;
        }
      }
      &-header,
      &-offer,
      &-reminder {
        text-align: center;
        width: 280px;
      }
      &-reminder {
        margin-top: 1.5em;
      }
      &-terms {
        display: none;
      }
    }
  }
}
/* Colorbox overrides */

.welcome15 {
  #colorbox {
    #cboxWrapper {
      #cboxContent {
        #cboxClose::before {
          background-color: $white;
          width: 12px;
          height: 12px;
          line-height: 1;
        }
        #cboxLoadedContent {
          background: transparent;
          padding: 0;
        }
      }
    }
  }
}
/* ----------- Tablet ----------- */

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  #colorbox #cboxWrapper #cboxContent #cboxClose {
    #{$ldirection}: auto;
    #{$rdirection}: 20px;
  }
}
/* ----------- Galaxy S5 ----------- */

/* Portrait and Landscape */
@media screen and (device-width: 360px) and (device-height: 640px) and (-webkit-device-pixel-ratio: 3) {
  .device-mobile {
    display: inline-block;
    .welcome-15 {
      min-width: 100%;
      width: 100%;
    }
  }
}
/* iPhone 6 in portrait & landscape */

@media only screen and (min-device-width: 375px) and (max-device-width: 667px) {
  .site-email-signup__success--welcome-15 {
    form {
      text-align: $ldirection;
      .email_input {
        input[type='email'] {
          line-height: 30px;
        }
      }
    }
  }
}
/* ----------- iPad mini, 1 and 2 ----------- */

/* Portrait and Landscape */

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
  .site-email-signup__success--welcome-15 {
    form {
      text-align: $ldirection;
      .email_input {
        input[type='email'] {
          line-height: 30px;
        }
      }
    }
  }
}
/* ----------- iPad 3 and 4 ----------- */

/* Portrait and Landscape */

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) {
  .site-email-signup__success--welcome-15 {
    form {
      text-align: $ldirection;
      .email_input {
        input[type='email'] {
          line-height: 30px;
        }
      }
    }
  }
}
/* ----------- Galaxy Tab 10.1 ----------- */

/* Portrait and Landscape */

@media (min-device-width: 800px) and (max-device-width: 1280px) {
  .site-email-signup__success--welcome-15 {
    form {
      text-align: $ldirection;
      .email_input {
        input[type='email'] {
          line-height: 20px;
        }
      }
    }
  }
}
/* Portrait more specific */

@media only screen and (device-width: 800px) and (device-height: 1280px) and (-webkit-device-pixel-ratio: 1) and (orientation: portrait) {
  .welcome15 {
    #colorbox {
      #{$ldirection}: 20% !important;
    }
  }
}
/* Landscape more specific */

@media only screen and (device-width: 1280px) and (device-height: 800px) and (-webkit-device-pixel-ratio: 1) and (orientation: landscape) {
  .welcome15 {
    #colorbox {
      #{$ldirection}: 30% !important;
    }
  }
}
/* ----------- Google Nexus 9 ----------- */

/* Portrait */

@media screen and (device-width: 768px) and (device-height: 1024px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
  .welcome15 {
    #colorbox {
      #{$ldirection}: 20% !important;
    }
  }
}
/* Landscape */

@media screen and (device-width: 1024px) and (device-height: 768px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
  .welcome15 {
    #colorbox {
      #{$ldirection}: 30% !important;
    }
  }
}
